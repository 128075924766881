import APIService from "./APIService";

export default class ContatoService {
  api = new APIService();

  async all(CodEnt, options) {
    try {
      const response = await this.api.send("get", "entidade/"+CodEnt+"/contato" + this.api.serverSideOptionsFormat(options),

      );
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async todos(CodEnt, options) {
    try {
      const response = await this.api.send("get", "contato/todos/" + CodEnt + this.api.serverSideOptionsFormat(options),

      );
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async search(src, options) {
    try {
      const response = await this.api.send("get", "contato/search/" + src + this.api.serverSideOptionsFormat(options));
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async searchFromEntidade(CodEnt, src, options) {
    try {
      const response = await this.api.send("get", "contato/search/" + CodEnt + "/" + src + this.api.serverSideOptionsFormat(options));
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async searchComEntidade(CodEnt, src, options) {
    try {
      const response = await this.api.send("get", "contato/searchComEntidade/" + CodEnt + "/" + src + this.api.serverSideOptionsFormat(options));
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async searchContatoBox(src) {
      try {
        const response = await this.api.send("get", "contato/eb/" +`?filtro=${src.filtro}${src.entidade != null ?"&entidade=" + src.entidade : ''}`);
        return response;
      }
      catch (e) {
        return false;
      }
    }

  async searchFromEntidade2(options) {
    try {
      const response = await this.api.send("get", "contato/search/" + options.codEnt, options);
      return response;
    }
    catch (e) {
      return false;
    }
  }


  async get(CodEnt) {
    try {
      const response = await this.api.send("get", "entidade/" + CodEnt);
      return response;
    }
    catch (e) {
      return false;
    }
  }


  async store(data) {
    try {
      const response = await this.api.send("post", "contato/store", data);
      if(!response){
        return this.api.result;
      }
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async update(data) {
    try {
      const response = await this.api.send("put", "contato/update/" + data.CODCON, data);
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async delete(data) {
    try {
      const response = await this.api.send("delete", "entidade/"+data.CODENT+"/contato/" + data.CODCON, data);
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async busca(codent, codcon) {
    try {
      const response = await this.api.send("get", "entidade/" + codent + "/contato/" + codcon);
      return response;
    }
    catch (e) {
      return false;
    }
  }
}